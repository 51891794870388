@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,600;0,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700;800&display=swap');



body {
  margin: 0;
  padding: 0;
  font-family: 'Nunito', sans-serif;
  /* font-family: 'Poppins', sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* scroll-behavior: smooth; */
}

body::-webkit-scrollbar {
  width: 10px;
  border-radius: 15px;
  transition: .3s ease-in;
}

body::-webkit-scrollbar:hover {
  width: 10px;
  transition: .3s ease-in;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
}

body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(11, 154, 214, .5);
}