@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,600;0,700;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700;800&display=swap);
body {
  margin: 0;
  padding: 0;
  font-family: 'Nunito', sans-serif;
  /* font-family: 'Poppins', sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* scroll-behavior: smooth; */
}

body::-webkit-scrollbar {
  width: 10px;
  border-radius: 15px;
  -webkit-transition: .3s ease-in;
  transition: .3s ease-in;
}

body::-webkit-scrollbar:hover {
  width: 10px;
  -webkit-transition: .3s ease-in;
  transition: .3s ease-in;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
}

body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(11, 154, 214, .5);
}

.rec.rec-carousel {
  /* border: 2px solid red; */
  padding: 10px;
  width: 95%;
}

.rec.rec-arrow {
  visibility: hidden;
}

.rec.rec-pagination {
  visibility: hidden;
}

@media screen and (max-width: 527px) {
  .rec.rec-carousel {
    padding: 0px;
    margin: 0;
  }
}

.project-caro {
  /* border: 2px solid red; */
  /* box-shadow: 0 5px 10px rgba(0, 0, 0, .4); */
  /* width: 500px; */
  /* position: relative; */
  /* place-content: center; */
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  /* width: 500px; */
  /* min-width: 200px; */
  /* text-align: center; */
  /* overflow: visible; */
  padding: 20px 30px;
  /* z-index: 1; */
}

@media screen and (max-width: 521px) {
  .caro {
    width: 400px;
  }
}

@media screen and (max-width: 420px) {
  .caro {
    width: 320px;
  }
}
.caro {
  /* border: 2px solid red; */
  width: 500px;
  position: relative;
  place-content: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 500px;
  /* min-width: 200px; */
  text-align: center;
  overflow: visible;
  padding: 10px;
}

@media screen and (max-width: 521px) {
  .caro {
    width: 400px;
  }
}

@media screen and (max-width: 420px) {
  .caro {
    width: 320px;
  }
}
@media screen and (max-width: 769px){
  .dataholder{
    margin: 10px;
  }
}
