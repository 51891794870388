.caro {
  /* border: 2px solid red; */
  width: 500px;
  position: relative;
  place-content: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 500px;
  /* min-width: 200px; */
  text-align: center;
  overflow: visible;
  padding: 10px;
}

@media screen and (max-width: 521px) {
  .caro {
    width: 400px;
  }
}

@media screen and (max-width: 420px) {
  .caro {
    width: 320px;
  }
}