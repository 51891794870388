.rec.rec-carousel {
  /* border: 2px solid red; */
  padding: 10px;
  width: 95%;
}

.rec.rec-arrow {
  visibility: hidden;
}

.rec.rec-pagination {
  visibility: hidden;
}

@media screen and (max-width: 527px) {
  .rec.rec-carousel {
    padding: 0px;
    margin: 0;
  }
}

.project-caro {
  /* border: 2px solid red; */
  /* box-shadow: 0 5px 10px rgba(0, 0, 0, .4); */
  /* width: 500px; */
  /* position: relative; */
  /* place-content: center; */
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  /* width: 500px; */
  /* min-width: 200px; */
  /* text-align: center; */
  /* overflow: visible; */
  padding: 20px 30px;
  /* z-index: 1; */
}

@media screen and (max-width: 521px) {
  .caro {
    width: 400px;
  }
}

@media screen and (max-width: 420px) {
  .caro {
    width: 320px;
  }
}